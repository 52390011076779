import React, { ReactElement, useContext, useEffect } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from 'gatsby'

import { GlobalContext } from '../../contexts/global.store'

export type RouterComponentPRops = {
  children?: React.ReactNode
}
export default function LoginGuardComponent({ children }: RouterComponentPRops): ReactElement {
  const [{ jwtToken }] = useContext(GlobalContext)
  const isLoggedIn = (): void => {
    if (!jwtToken) navigate('/login')
  }
  useEffect(isLoggedIn, [])
  useEffect(isLoggedIn, [jwtToken])
  return <>{children}</>
}
